// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/scottarnold/Desktop/lauv-songs/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-help-js": () => import("/Users/scottarnold/Desktop/lauv-songs/src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-index-js": () => import("/Users/scottarnold/Desktop/lauv-songs/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-modernloneliness-js": () => import("/Users/scottarnold/Desktop/lauv-songs/src/pages/modernloneliness.js" /* webpackChunkName: "component---src-pages-modernloneliness-js" */),
  "component---src-pages-preorder-js": () => import("/Users/scottarnold/Desktop/lauv-songs/src/pages/preorder.js" /* webpackChunkName: "component---src-pages-preorder-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/scottarnold/Desktop/lauv-songs/.cache/data.json")

